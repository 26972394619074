// ** Api Endpoints
// const domain = "localhost"
// const domain = "192.168.1.4"
// const domain = "aceuss.3mad.in"
// const domain = "aceuss.3mad.in"
// const domain = "localhost:8000"
//const domain = 'smspannel.newrise.in'
// const domain = 'satocci.gofactz.com'
// const domain = 'test.ok-go.in'
//const domainSocket = '3.75.64.173'
const domainSocket = 'api.satoccifinance.se/wss2/'
const domain = 'api.satoccifinance.se'
//const domain2 = 'stagingapi.satoccifinance.se'
//const domain = 'satocci.enc.gofactz.com'
//const domain = 'satocci.com'
export default {
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  entryPoint: 'web',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'satocci_access_token',
  storageRefreshTokenKeyName: 'satocci_access_token',

  // base api urls
  //https replace to http  with last commit
  baseUrl: `https://${domain}/api/`,
  baseUrl2: `https://${domain}/`,
  baseUrl3: `https://${domain}`,

  enableSocket: true,
  // socketChatUrl: `ws://${domainSocket}:8090`,
  socketChatUrl: `wss://${domainSocket}:8090`,
  //8091
  //6002
  //socketNotificationUrl: '3.75.64.173',
  socketNotificationUrl: `${domain}`,
  //for production 6001
  //for staging 6002
  socketNotificationPort: 6001,

  encryptKey: 'C&E)H@McQfTjWnZr4u7x!A%D*G-JaNdR',
  enableAES: true
}

export interface ResponseWithPagination<T> {
  current_page: string
  last_page: number
  per_page: string
  total: number
  promotions?: any
  data?: Array<T>
}

export interface ResponseParamsType<T> {
  code: number
  message: any
  payload: T
  success: boolean
  data?: any
}

export interface ResponseParamsTypeWithPagination<T> {
  code: number
  message: any
  error?: ResponseParamsType<T>
  data?: any
  payload: ResponseWithPagination<T>
  success: boolean
  year?: any
}

export interface ViewParams {
  userType?: any
  id?: number | string
  originalArgs?: any
  eventId?: any
  store_id?: any
  ids?: any
  currency?: any

  page?: any
  least?: any
  jsonData?: any
  request_for?: any
  data_of?: any
  year?: any
  per_page_record?: any
}

export interface RequestParamsWithPagePerPage {
  page?: number | string
  per_page_record?: number
}

export interface RequestParamsWithParentId {
  id?: any
  page?: number
  per_page_record?: number
}
