/* Action = Permission, Resource = Group-Group */
// export interface PermissionType {
//   action?: any
//   resource?: any
//   belongs_to?: any
//   dashboardBrowse?: any
//   storeBrowse?: any
//   storeEdit?: any
//   storeAdd?: any
//   storeDelete?: any
//   storeRead?: any
//   productBrowse?: any
//   productRead?: any
//   productEdit?: any
//   productAdd?: any
//   productDelete?: any
//   ///SubStore
//   subStoreBrowse?: any
//   subStoreEdit?: any
//   subStoreAdd?: any
//   subStoreDelete?: any
//   subStoreRead?: any
//   /////Store Employee
//   employeeBrowse?: any
//   employeeEdit?: any
//   employeeAdd?: any
//   employeeDelete?: any
//   employeeRead?: any
//   ////Employee Admin
//   employeeAdminBrowse?: any
//   employeeAdminEdit?: any
//   employeeAdminAdd?: any
//   employeeAdminDelete?: any
//   employeeAdminRead?: any
//   sessionBrowse?: any
//   sessionEdit?: any
//   sessionAdd?: any
//   sessionDelete?: any
//   sessionRead?: any
//   ////Categories
//   categoryBrowse?: any
//   categoryEdit?: any
//   categoryAdd?: any
//   categoryDelete?: any
//   categoryRead?: any
//   ///Language
//   languageBrowse?: any
//   languageEdit?: any
//   languageAdd?: any
//   languageDelete?: any
//   languageRead?: any
//   ///Feedback
//   feedbackBrowse?: any
//   feedbackEdit?: any
//   feedbackAdd?: any
//   feedbackDelete?: any
//   feedbackRead?: any
//   ////Email Template
//   emailTemplateBrowse?: any
//   emailTemplateEdit?: any
//   emailTemplateAdd?: any
//   emailTemplateDelete?: any
//   emailTemplateRead?: any
//   ///App Settings
//   appSettingsBrowse?: any
//   appSettingsEdit?: any

// do not remove
export type PermissionType = {
  action: string
  resource: string
  belongs_to?: any
}
export const Permissions = Object.freeze({
  // Dashboard
  dashboardBrowse: {
    action: 'dashboard-browse',
    resource: 'all',
    belongs_to: 3
  },
  ////Stores
  storeBrowse: {
    action: 'store-browse',
    resource: 'store',
    belongs_to: 3
  },

  storeEdit: {
    action: 'store-edit',
    resource: 'store',
    belongs_to: 3
  },
  storeAdd: {
    action: 'store-add',
    resource: 'store',
    belongs_to: 3
  },
  storeDelete: {
    action: 'store-delete',
    resource: 'store',
    belongs_to: 3
  },
  storeRead: {
    action: 'store-read',
    resource: 'store',
    belongs_to: 3
  },

  //coupon
  couponBrowse: {
    action: 'coupon-browse',
    resource: 'coupon',
    belongs_to: 3
  },
  couponAdd: {
    action: 'coupon-add',
    resource: 'coupon',
    belongs_to: 3
  },
  couponRead: {
    action: 'coupon-read',
    resource: 'coupon',
    belongs_to: 3
  },
  couponEdit: {
    action: 'coupon-edit',
    resource: 'coupon',
    belongs_to: 3
  },
  couponAction: {
    action: 'coupon-action',
    resource: 'coupon',
    belongs_to: 3
  },

  ///Products
  productBrowse: {
    action: 'product-browse',
    resource: 'product',
    belongs_to: 3
  },
  productRead: {
    action: 'product-read',
    resource: 'product',
    belongs_to: 3
  },

  productEdit: {
    action: 'product-edit',
    resource: 'product',
    belongs_to: 3
  },
  productAdd: {
    action: 'product-add',
    resource: 'product',
    belongs_to: 3
  },

  productDelete: {
    action: 'product-delete',
    resource: 'product',
    belongs_to: 3
  },

  /////Store Employee
  employeeBrowse: {
    action: 'employee-browse',
    resource: 'employee',
    belongs_to: 3
  },
  employeeEdit: {
    action: 'employee-edit',
    resource: 'employee',
    belongs_to: 3
  },
  employeeAdd: {
    action: 'employee-add',
    resource: 'employee',
    belongs_to: 3
  },
  employeeDelete: {
    action: 'employee-delete',
    resource: 'employee',
    belongs_to: 3
  },
  employeeRead: {
    action: 'employee-read',
    resource: 'employee',
    belongs_to: 3
  },
  ////Employee Admin
  employeeAdminBrowse: {
    action: 'employeeAdmin-browse',
    resource: 'employeeAdmin',
    belongs_to: 1
  },
  employeeAdminEdit: {
    action: 'employeeAdmin-edit',
    resource: 'employeeAdmin',
    belongs_to: 1
  },
  employeeAdminAdd: {
    action: 'employeeAdmin-add',
    resource: 'employeeAdmin',
    belongs_to: 1
  },
  employeeAdminDelete: {
    action: 'employeeAdmin-delete',
    resource: 'employeeAdmin',
    belongs_to: 1
  },
  employeeAdminRead: {
    action: 'employeeAdmin-read',
    resource: 'employeeAdmin',
    belongs_to: 1
  },

  sessionBrowse: {
    action: 'session-browse',
    resource: 'session',
    belongs_to: 2
  },
  sessionEdit: {
    action: 'session-edit',
    resource: 'session',
    belongs_to: 2
  },
  sessionAdd: {
    action: 'session-add',
    resource: 'session',
    belongs_to: 2
  },
  sessionDelete: {
    action: 'session-delete',
    resource: 'session',
    belongs_to: 2
  },
  sessionRead: {
    action: 'session-read',
    resource: 'session',
    belongs_to: 2
  },
  ////Categories
  categoryBrowse: {
    action: 'category-browse',
    resource: 'category',
    belongs_to: 3
  },
  categoryEdit: {
    action: 'category-edit',
    resource: 'category',
    belongs_to: 3
  },
  categoryAdd: {
    action: 'category-add',
    resource: 'category',
    belongs_to: 3
  },
  categoryDelete: {
    action: 'category-delete',
    resource: 'category',
    belongs_to: 3
  },
  categoryRead: {
    action: 'category-read',
    resource: 'category',
    belongs_to: 3
  },
  ///Language
  languageBrowse: {
    action: 'language-browse',
    resource: 'language',
    belongs_to: 1
  },
  languageEdit: {
    action: 'language-edit',
    resource: 'language',
    belongs_to: 1
  },
  languageAdd: {
    action: 'language-add',
    resource: 'language',
    belongs_to: 1
  },
  languageDelete: {
    action: 'language-delete',
    resource: 'language',
    belongs_to: 1
  },
  languageRead: {
    action: 'language-read',
    resource: 'language',
    belongs_to: 1
  },
  ///Feedback
  feedbackBrowse: {
    action: 'feedback-browse',
    resource: 'feedback',
    belongs_to: 3
  },
  feedbackEdit: {
    action: 'feedback-edit',
    resource: 'feedback',
    belongs_to: 3
  },
  feedbackAdd: {
    action: 'feedback-add',
    resource: 'feedback',
    belongs_to: 3
  },
  feedbackDelete: {
    action: 'feedback-delete',
    resource: 'feedback',
    belongs_to: 3
  },
  feedbackRead: {
    action: 'feedback-read',
    resource: 'feedback',
    belongs_to: 3
  },
  ////Email Template
  emailTemplateBrowse: {
    action: 'emailTemplate-browse',
    resource: 'emailTemplate',
    belongs_to: 1
  },
  emailTemplateEdit: {
    action: 'emailTemplate-edit',
    resource: 'emailTemplate',
    belongs_to: 1
  },
  emailTemplateAdd: {
    action: 'emailTemplate-add',
    resource: 'emailTemplate',
    belongs_to: 1
  },
  emailTemplateDelete: {
    action: 'emailTemplate-delete',
    resource: 'emailTemplate',
    belongs_to: 1
  },
  emailTemplateRead: {
    action: 'emailTemplate-read',
    resource: 'emailTemplate',
    belongs_to: 1
  },

  ///notification tempalate
  notificationTemplateBrowse: {
    action: 'notification-template-browse',
    resource: 'notification-template',
    belongs_to: 1
  },
  notificationTemplateEdit: {
    action: 'notification-template-edit',
    resource: 'notification-template',
    belongs_to: 1
  },
  notificationTemplateAdd: {
    action: 'notification-template-add',
    resource: 'notification-template',
    belongs_to: 1
  },
  notificationTemplateDelete: {
    action: 'notification-template-delete',
    resource: 'notification-template',
    belongs_to: 1
  },
  notificationTemplateRead: {
    action: 'notification-template-read',
    resource: 'notification-template',
    belongs_to: 1
  },
  ///App Settings
  appSettingsBrowse: {
    action: 'appSettings-browse',
    resource: 'appSettings',
    belongs_to: 1
  },
  appSettingsEdit: {
    action: 'appSettings-edit',
    resource: 'appSettings',
    belongs_to: 1
  },

  appSettingsAdd: {
    action: 'appSettings-add',
    resource: 'appSettings',
    belongs_to: 1
  },
  appSettingsDelete: {
    action: 'appSettings-delete',
    resource: 'appSettings',
    belongs_to: 1
  },
  appSettingsRead: {
    action: 'appSettings-read',
    resource: 'appSettings',
    belongs_to: 1
  },
  ////Roles
  rolesBrowse: {
    action: 'roles-browse',
    resource: 'roles',
    belongs_to: 3
  },
  rolesEdit: {
    action: 'roles-edit',
    resource: 'roles',
    belongs_to: 3
  },
  rolesAdd: {
    action: 'roles-add',
    resource: 'roles',
    belongs_to: 3
  },
  rolesDelete: {
    action: 'roles-delete',
    resource: 'roles',
    belongs_to: 3
  },
  rolesRead: {
    action: 'roles-read',
    resource: 'roles',
    belongs_to: 3
  },

  ////Customer
  customerBrowse: {
    action: 'customer-browse',
    resource: 'customer',
    belongs_to: 2
  },
  customerEdit: {
    action: 'customer-edit',
    resource: 'customer',
    belongs_to: 2
  },
  customerAdd: {
    action: 'customer-add',
    resource: 'customer',
    belongs_to: 2
  },
  customerDelete: {
    action: 'customer-delete',
    resource: 'customer',
    belongs_to: 2
  },
  customerRead: {
    action: 'customer-read',
    resource: 'customer',
    belongs_to: 2
  },
  ////Reports
  reportsBrowse: {
    action: 'report-browse',
    resource: 'report',
    belongs_to: 3
  },
  reportsEdit: {
    action: 'report-edit',
    resource: 'report',
    belongs_to: 3
  },
  reportsAdd: {
    action: 'report-add',
    resource: 'report',
    belongs_to: 3
  },
  reportsDelete: {
    action: 'report-delete',
    resource: 'report',
    belongs_to: 3
  },
  reportsRead: {
    action: 'report-read',
    resource: 'report',
    belongs_to: 3
  },
  ///Transaction
  transactionBrowse: {
    action: 'transaction-browse',
    resource: 'transaction',
    belongs_to: 3
  },
  transactionEdit: {
    action: 'transaction-edit',
    resource: 'transaction',
    belongs_to: 3
  },
  transactionAdd: {
    action: 'transaction-add',
    resource: 'transaction',
    belongs_to: 3
  },
  transactionDelete: {
    action: 'transaction-delete',
    resource: 'transaction',
    belongs_to: 3
  },
  transactionRead: {
    action: 'transaction-read',
    resource: 'transaction',
    belongs_to: 3
  },
  ///Labels
  labelBrowse: {
    action: 'label-browse',
    resource: 'label',
    belongs_to: 1
  },
  labelEdit: {
    action: 'label-edit',
    resource: 'label',
    belongs_to: 1
  },
  labelAdd: {
    action: 'label-add',
    resource: 'label',
    belongs_to: 1
  },
  labelDelete: {
    action: 'label-delete',
    resource: 'label',
    belongs_to: 1
  },
  labelRead: {
    action: 'label-read',
    resource: 'label',
    belongs_to: 1
  },

  ///Subscription
  subscriptionBrowse: {
    action: 'subscription-browse',
    resource: 'subscription',
    belongs_to: 1
  },
  subscriptionEdit: {
    action: 'subscription-edit',
    resource: 'subscription',
    belongs_to: 1
  },
  subscriptionAdd: {
    action: 'subscription-add',
    resource: 'subscription',
    belongs_to: 1
  },
  subscriptionDelete: {
    action: 'subscription-delete',
    resource: 'subscription',
    belongs_to: 1
  },
  subscriptionRead: {
    action: 'subscription-read',
    resource: 'subscription',
    belongs_to: 1
  },
  //StoreCustomer

  storeCustomerBrowse: {
    action: 'storeCustomer-browse',
    resource: 'storeCustomer',
    belongs_to: 3
  },

  // Content & promotion
  contentBrowse: {
    action: 'content-browse',
    resource: 'content',
    belongs_to: 1
  },
  contentAdd: {
    action: 'content-add',
    resource: 'content',
    belongs_to: 1
  },
  contentEdit: {
    action: 'content-edit',
    resource: 'content',
    belongs_to: 1
  },
  contentRead: {
    action: 'content-read',
    resource: 'content',
    belongs_to: 1
  },
  contentDelete: {
    action: 'content-delete',
    resource: 'content',
    belongs_to: 1
  },
  contentLog: {
    action: 'content-log',
    resource: 'content',
    belongs_to: 1
  },
  contentSend: {
    action: 'content-send',
    resource: 'content',
    belongs_to: 1
  },
  referralsBrowse: {
    action: 'referral',
    resource: 'referral',
    belongs_to: 1
  },

  generateBarcode: {
    action: 'generate',
    resource: 'barcode',
    belongs_to: 2
  },
  ///return object
  returnRefundBrowse: {
    action: 'list-return-and-refund-order',
    resource: 'return & refund',
    belongs_to: 2
  },

  approveReject: {
    action: 'approve-reject-return',
    resource: 'return & refund',
    belongs_to: 2
  },
  returnRefundAdd: {
    action: 'create-return-and-refund-order',
    resource: 'return & refund',
    belongs_to: 2
  },
  refundInitiated: {
    action: 'refund-return',
    resource: 'return & refund',
    belongs_to: 2
  }
})
