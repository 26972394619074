/* eslint-disable prettier/prettier */
export default {
  login: 'login',
  logout: 'logout',
  otpVerify: 'otp-verify',
  resendOtp: 'resend-otp',
  forgotPassword: 'forgot-password',
  resetPassword: 'update-password',
  updateProfile: 'update-profile',
  load_activity: 'administration/activities',
  store_activity: 'activities',
  load_activity_id: 'administration/activities-info/',
  load_gatekeeper_activity: 'order-verification-log',
  postLanguages: 'administration/post-languages',
  orderRating: 'administration/order-rating',
  auto_update_password_all_acccount: 'administration/auto-update-password-all-acccount',
  download_password_file: 'administration/download-password-file',
  requestAccountDelete: 'request-account-delete',
  verifyDeleteOtp: 'verify-del-otp',
  updatePasswordAnyUser: 'update-password-any-user',
  allUserList: 'get-all-users',
  ///Category  ///
  add_category: 'category',
  load_category: 'category',
  load_subcategory: 'sub-categories/',
  edit_category: 'category/',

  view_category: 'category/',
  delete_category: 'category/',
  get_categories_subcategories: 'get-categories-subcategories',
  action_category: 'category-action',
  ///Products ///

  ///Notification
  createNotification: 'notification',
  notifications: 'notifications',
  statusRead: 'notification/',
  readAllNotification: 'user-notification-read-all',
  unreadNotificationCount: 'unread-notification-count',
  notificationShow: 'notification/',
  notificationDelete: 'notification/',
  userNotificationDelete: 'user-notification-delete',
  notificationTestSend: 'notification-check',

  //notification
  load_product: 'products',
  import_multiple_barcode: 'product-variant-barcode-import',
  import_multiple_barcode_sample: 'import-barcode-sample-file',
  load_product_variant: 'product-variants/',
  update_product_variant: 'product-variant/',
  add_variant: 'product-variant',
  delete_product_variant: 'product-variant/',
  load_product_variant_view: 'product-variant/',
  add_product: 'product',
  view_product: 'product/',
  edit_product: 'product/',
  delete_product: 'product/',
  trashed_product_list: 'trashed-products',
  restore_product: 'restore-product/',
  action_product: 'product-action',
  action_variant: 'product-variant-action',
  action_offer: 'product-offer-action',
  import_product: 'products-import',
  sampleFileExport: 'import-products-sample-file',
  transaction_by_product: 'product-wise-transaction',
  product_wise_transaction: 'product-wise-transactions',
  ///Stores//
  load_stores: 'administration/stores',
  add_stores: 'administration/store',
  subscription_applied_list: 'administration/store-subscription-applied-list/', // subscription
  delete_applied_subscription: 'administration/delete-store-subscription-applied/', //subscription
  apply_subscription: 'administration/store-subscription-applied', //subscription
  view_stores: 'administration/store/',
  edit_stores: 'administration/store/',
  delete_stores: 'administration/store/',
  action_store: 'administration/store-action',
  trashed_stores: 'administration/trashed-stores',
  restore_store: 'administration/restore-store/',
  store_action: 'administration/store-action',
  store_substore_list: 'get-store-substores/',
  load_common_store: 'get-store-details/',
  currency_wise_report: 'administration/report-currency-wise',
  storeReports: 'report',
  ////Group///
  load_group: 'administration/groups',
  add_group: 'administration/group',
  view_group: 'administration/group/',
  edit_group: 'administration/group/',
  delete_group: 'administration/group/',
  ////Labels///
  load_labels: 'administration/labels',
  add_labels: 'administration/label',
  view_labels: 'administration/label/',
  edit_labels: 'administration/label/',
  delete_labels: 'administration/label/',
  label_export: 'administration/labels-export', //Post method
  label_import: 'administration/labels-import', //post method
  ///language//
  language_list: 'administration/language',
  language_add: 'administration/language',
  language_list_store: 'store-language/',
  language_edit: 'administration/language/',
  language_delete: 'administration/language/',
  language_view: 'administration/language/',

  ///Commons//
  service_feedback_view: 'service-feedback/',
  service_feedback_add: 'service-feedback',
  service_feedback_edit: 'service-feedback/',
  service_feedback_delete: 'service-feedback/',
  service_feedback_list: 'service-feedback',
  download_summary_report: 'download-summery-report',
  //employee///
  employee_list: 'employees',
  employee_add: 'employee',
  employee_edit: 'employee/',
  employee_delete: 'employee/',
  employee_view: 'employee/',
  employee_trash: 'trashed-employees',
  employee_restore: 'restore-employee/',
  employee_action: 'employee-action',
  ///Group//
  group_list: 'administration/groups',
  group_add: 'administration/group',
  group_edit: 'administration/group/',
  group_delete: 'administration/group/',
  group_view: 'administration/group/',
  ///Customer///
  customer_list: 'administration/customers',
  customer_store_wise: 'customers-store-wise',
  customer_add: 'administration/customer',
  customer_edit: 'administration/customer/',
  customer_delete: 'administration/customer/',
  customer_view: 'administration/customer/',
  customer_shopping_data: 'customers-shopping-data/',
  storeCustomerList: 'store-customers',
  find_label_not_added_lang_wise: 'administration/find-label-not-added-lang-wise/',
  add_labels_if_not_found: 'administration/add-labels-if-not-found',
  exportStoreCustomer: 'export-store-customers',
  customerAction: 'administration/customer-action',
  ///SubStore//
  subStore_list: 'sub-stores',
  subStore_add: 'sub-store',
  subStore_edit: 'sub-store/',
  subStore_delete: 'sub-store/',
  subStore_view: 'sub-store/',
  subStore_action: 'sub-store-action',
  subStore_trashed_list: 'trashed-sub-stores',
  subStore_restore: 'restore-sub-store/',
  store_customer_list_filter: 'store-customers-filter',

  ///Roles///
  role_list: 'roles',
  role_add: 'role',
  role_edit: 'role/',
  role_delete: 'role/',
  role_view: 'role/',
  role_action: 'role',
  ///Admin Employee ////
  admin_emp_list: 'employees',
  admin_emp_add: 'employee',
  admin_emp_edit: 'employee/',
  admin_emp_delete: 'employee/',
  admin_emp_view: 'employee/',
  admin_emp_trash: 'trashed-employees',
  admin_emp_restore: 'restore-employee/',
  admin_emp_action: 'action-employee',
  ///File Upload ///
  file_upload: 'file-upload',
  ///Card Details//
  card_list: 'card-detail', //get method
  card_add: 'card-detail',
  card_update: 'card-detail/',
  card_view: 'card-detail/',
  card_delete: 'card-detail/',
  ///transactions
  transaction_list: 'transaction-detail',
  transaction_add: 'transaction-detail',
  transaction_update: 'transaction-detail/',
  transaction_view: 'transaction-detail/',
  transaction_delete: 'transaction-detail/',
  //// Email And Template
  email_template_list: 'administration/notification-templates',
  email_template_add: 'administration/notification-template',
  email_template_update: 'administration/notification-template/',
  email_template_view: 'administration/notification-template/',
  email_template_delete: 'administration/notification-template/',
  //// Promotion Template
  promotion_template_list: 'contents',
  promotion_template_add: 'content',
  promotion_template_update: 'content/',
  promotion_template_view: 'content/',
  promotion_template_delete: 'content/',
  promotion_template_send: 'send-message',
  promotion_template_log: 'promotions',
  ///App Settings\
  app_setting_list: 'administration/appSettings',
  app_setting_add: 'administration/appSetting',
  app_setting_update: 'administration/appSetting',
  app_setting_view: 'administration/appSetting/',
  app_setting_delete: 'administration/appSetting/',
  ////Permissions
  permissions_list: 'administration/permissions',
  permissions_add: 'administration/permission',
  permissions_update: 'administration/permission/',
  permissions_view: 'administration/permission/',
  permissions_delete: 'administration/permission/',

  ///////Coupons
  coupon_delete: '',
  coupon_view: 'coupon/',
  coupon_add: 'coupon',
  coupon_edit: 'coupon/',
  coupon_list: 'coupon',
  coupon_action: 'coupon-action',
  couponCodeUsage: 'coupon-code-usage',
  store_option: 'administration/stores',
  //ProcessingFeeSetup
  processing_fee_setup_list: 'administration/payment-processing-fee-setups',
  processing_fee_setup_add: 'administration/payment-processing-fee-setup',
  processing_fee_setup_update: 'administration/payment-processing-fee-setup/',
  processing_fee_setup_view: 'administration/payment-processing-fee-setup/',
  processing_fee_setup_delete: 'administration/payment-processing-fee-setup/',

  uploadFiles: 'file-upload',
  test: 'testing-enc-dec',
  get_countries: 'get-countries',

  ////// offer
  addProductOffer: 'product-offer',
  editProductOffer: 'product-offer/',
  listProductOffer: 'product-offers',
  deleteProductOffer: 'product-offer/',

  ///Orders
  listOrders: 'store-orders',
  createOrder: 'order',
  resentInvoice: 'resent-invoice/',
  detailsOrder: 'store-order-detail/',
  createStripeIntent: 'create-stripe-intent',
  orderPaymentStatusUpdate: 'order-payment-status-update/',
  customerInvoiceList: 'generate-invoice/',
  generateInvoiceStore: 'generate-invoice-store/',
  exportOrders: 'export-orders',
  exportOrdersWithProducts: 'export-order-details',
  /// Subscription
  view_subscription: '',
  load_subscription: 'administration/store-subscriptions',
  subscription_logs: 'administration/store-subscription-logs',
  subs_log_action: 'administration/store-subscription-action',
  export_subscription_log: 'administration/store-subscription-logs-export',
  all_store_subscription_log: 'administration/all-store-subscription-logs',

  pending_store_amount: 'administration/pending-stores-order-amount-to-transfer',
  update_order_transfer: 'administration/update-order-transfer-status',

  // top stores
  load_top_stores: 'top-selling-stores',
  least_selling_stores: 'least-selling-stores',
  top_selling_products: 'top-selling-products',
  least_selling_products: 'least-selling-products',
  adminReports: 'administration/report',
  // connect stripe
  connect_stripe: 'create-stripe-connect-account',
  check_stripe_current_status: 'check-stripe-account-current-status/',
  regenerate_stripe_connect_link: 'regenerate-stripe-connect-account-link',

  ///common permission list
  store_permissionList: 'permissions',
  revenueReport: 'revenue-report',
  topCategories: 'category-wise-report',
  storeOrderInvoice: 'generate-invoice-store/',
  myProfile: 'my-profile',

  openVar: 'open-variants',
  openVarBar: 'open-variants-barcodes',
  addOpenBarcodes: 'add-open-product-barcode',

  /// Product Stats
  product_sale_stats: 'product-selling-graph',
  product_sale_stats_month: 'product-selling-graph-monthwise',
  store_product_sales_stats: 'store-earning-graph-datewise',
  transaction_wise_report: 'product-selling-graph-monthwise',
  download_zip_file: 'download-barcode-zip/',

  // search
  productSearch: 'product-and-variant-search',

  // customer Login
  display_login_settings: 'administration/diaplay-login-setting',
  get_login_settings: 'administration/get-diaplay-login-setting',

  //refferals

  referrals: 'administration/referrals',
  refer_family_friends: 'administration/refer-family-friends',
  bulk_referral: 'bulk-referral',
  list_bulk_referral: 'bulk-referrals',
  bulk_referral_sample_file: 'bulk-referral-sample-file',

  // returns
  returnOrder: 'return-order',
  orderReturns: 'order-returns',
  getAmountReturn: 'get-amount-return-order',
  returnOrderAction: 'return-order-action',
  returnOrderRefund: 'return-order-refund',
  returnOrderDetails: 'order-return-detail/',
  // Currency
  get_currency_list: 'get-currencies',
  loginLog: 'user-logs',
  deleteAccountReq: 'administration/account-delete-requests',
  categories_import: 'categories-import',
  category_sample_file: 'category-sample-file',
  get_units: 'get-units',
  print_product_barcode_with_details: 'download-barcodes-pdf',
  export_products: 'products-export',
  updateMinCartValueCountryWise: 'administration/update-min-cart-value-country-wise'
}
